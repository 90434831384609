
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}

input#able-giq {
    display: none;
}

img.calendar {
    background-color: white !important;
}
